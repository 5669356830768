/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export enum KeyType {
  UNKNOWN = 0,
  STRING = 1,
  BOOL = 2,
  PRICE = 3,
  TIME_IN_MS = 4,
  K_DATE = 5,
  PERCENTAGE = 6,
  K_NUMBER = 7,
  UNRECOGNIZED = -1,
}

export function keyTypeFromJSON(object: any): KeyType {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return KeyType.UNKNOWN;
    case 1:
    case "STRING":
      return KeyType.STRING;
    case 2:
    case "BOOL":
      return KeyType.BOOL;
    case 3:
    case "PRICE":
      return KeyType.PRICE;
    case 4:
    case "TIME_IN_MS":
      return KeyType.TIME_IN_MS;
    case 5:
    case "K_DATE":
      return KeyType.K_DATE;
    case 6:
    case "PERCENTAGE":
      return KeyType.PERCENTAGE;
    case 7:
    case "K_NUMBER":
      return KeyType.K_NUMBER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return KeyType.UNRECOGNIZED;
  }
}

export function keyTypeToJSON(object: KeyType): string {
  switch (object) {
    case KeyType.UNKNOWN:
      return "UNKNOWN";
    case KeyType.STRING:
      return "STRING";
    case KeyType.BOOL:
      return "BOOL";
    case KeyType.PRICE:
      return "PRICE";
    case KeyType.TIME_IN_MS:
      return "TIME_IN_MS";
    case KeyType.K_DATE:
      return "K_DATE";
    case KeyType.PERCENTAGE:
      return "PERCENTAGE";
    case KeyType.K_NUMBER:
      return "K_NUMBER";
    case KeyType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ChartType {
  UNKNOWN_CHART_TYPE = 0,
  PIE = 1,
  BAR_VERTICAL = 2,
  BAR_HORIZONTAL = 3,
  PIE_ADVANCED = 4,
  PIE_GRID = 5,
  TREE_MAP = 6,
  NUMBER_CARD = 7,
  GAUGE = 8,
  LINE = 9,
  VERTICAL_STACKED_BAR = 10,
  HORIZONTAL_STACKED_BAR = 11,
  UNRECOGNIZED = -1,
}

export function chartTypeFromJSON(object: any): ChartType {
  switch (object) {
    case 0:
    case "UNKNOWN_CHART_TYPE":
      return ChartType.UNKNOWN_CHART_TYPE;
    case 1:
    case "PIE":
      return ChartType.PIE;
    case 2:
    case "BAR_VERTICAL":
      return ChartType.BAR_VERTICAL;
    case 3:
    case "BAR_HORIZONTAL":
      return ChartType.BAR_HORIZONTAL;
    case 4:
    case "PIE_ADVANCED":
      return ChartType.PIE_ADVANCED;
    case 5:
    case "PIE_GRID":
      return ChartType.PIE_GRID;
    case 6:
    case "TREE_MAP":
      return ChartType.TREE_MAP;
    case 7:
    case "NUMBER_CARD":
      return ChartType.NUMBER_CARD;
    case 8:
    case "GAUGE":
      return ChartType.GAUGE;
    case 9:
    case "LINE":
      return ChartType.LINE;
    case 10:
    case "VERTICAL_STACKED_BAR":
      return ChartType.VERTICAL_STACKED_BAR;
    case 11:
    case "HORIZONTAL_STACKED_BAR":
      return ChartType.HORIZONTAL_STACKED_BAR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ChartType.UNRECOGNIZED;
  }
}

export function chartTypeToJSON(object: ChartType): string {
  switch (object) {
    case ChartType.UNKNOWN_CHART_TYPE:
      return "UNKNOWN_CHART_TYPE";
    case ChartType.PIE:
      return "PIE";
    case ChartType.BAR_VERTICAL:
      return "BAR_VERTICAL";
    case ChartType.BAR_HORIZONTAL:
      return "BAR_HORIZONTAL";
    case ChartType.PIE_ADVANCED:
      return "PIE_ADVANCED";
    case ChartType.PIE_GRID:
      return "PIE_GRID";
    case ChartType.TREE_MAP:
      return "TREE_MAP";
    case ChartType.NUMBER_CARD:
      return "NUMBER_CARD";
    case ChartType.GAUGE:
      return "GAUGE";
    case ChartType.LINE:
      return "LINE";
    case ChartType.VERTICAL_STACKED_BAR:
      return "VERTICAL_STACKED_BAR";
    case ChartType.HORIZONTAL_STACKED_BAR:
      return "HORIZONTAL_STACKED_BAR";
    case ChartType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ChartData {
  id?: string | undefined;
  name?: string | undefined;
  value?: number | undefined;
  series: ChartData_ChartSeries[];
}

export interface ChartData_ChartSeries {
  name?: string | undefined;
  value?: number | undefined;
}

function createBaseChartData(): ChartData {
  return { id: undefined, name: undefined, value: undefined, series: [] };
}

export const ChartData = {
  encode(message: ChartData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.value !== undefined) {
      writer.uint32(25).double(message.value);
    }
    for (const v of message.series) {
      ChartData_ChartSeries.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChartData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChartData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.value = reader.double();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.series.push(ChartData_ChartSeries.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChartData {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      value: isSet(object.value) ? Number(object.value) : undefined,
      series: Array.isArray(object?.series) ? object.series.map((e: any) => ChartData_ChartSeries.fromJSON(e)) : [],
    };
  },

  toJSON(message: ChartData): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.value !== undefined && (obj.value = message.value);
    if (message.series) {
      obj.series = message.series.map((e) => e ? ChartData_ChartSeries.toJSON(e) : undefined);
    } else {
      obj.series = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChartData>, I>>(base?: I): ChartData {
    return ChartData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ChartData>, I>>(object: I): ChartData {
    const message = createBaseChartData();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.value = object.value ?? undefined;
    message.series = object.series?.map((e) => ChartData_ChartSeries.fromPartial(e)) || [];
    return message;
  },
};

function createBaseChartData_ChartSeries(): ChartData_ChartSeries {
  return { name: undefined, value: undefined };
}

export const ChartData_ChartSeries = {
  encode(message: ChartData_ChartSeries, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== undefined) {
      writer.uint32(17).double(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChartData_ChartSeries {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChartData_ChartSeries();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.value = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChartData_ChartSeries {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      value: isSet(object.value) ? Number(object.value) : undefined,
    };
  },

  toJSON(message: ChartData_ChartSeries): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<ChartData_ChartSeries>, I>>(base?: I): ChartData_ChartSeries {
    return ChartData_ChartSeries.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ChartData_ChartSeries>, I>>(object: I): ChartData_ChartSeries {
    const message = createBaseChartData_ChartSeries();
    message.name = object.name ?? undefined;
    message.value = object.value ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
